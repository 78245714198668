
import './App.css';
import Footer from './components/footer/Footer';
import AboutUs from './screens/about-us/AboutUs';
import ContactWith from './screens/contactwith/ContactWith';
import InnovativeProduct from './screens/innovativeproduct/InnovativeProduct';
import OpeningGuideLevit from './screens/openingguidelevit/OpeningGuideLevit';
import OurGallery from './screens/ourgallery/OurGallery';
import StandingDesk from './screens/standingdesk/StandingDesk';
import UsersSay from './screens/userssay/UsersSay';
import WhatIsLevit from './screens/whatislevit/WhatIsLevit';
import WhoAreWe from './screens/whoarewe/WhoAreWe';
import WhoIsLevit from './screens/whoislevit/WhoIsLevit';
import WhyLevit from './screens/whylevit/WhyLevit';


function App() {
  return (
    <div className="App">
      <AboutUs/>
      <WhatIsLevit/>
      <WhoIsLevit/>
      <WhoAreWe/>
      <OpeningGuideLevit/>
      <WhyLevit/>
      <InnovativeProduct/>
      <StandingDesk/>
      <OurGallery/>
      <UsersSay/>
      <ContactWith/>
      <Footer/>
    </div>
  );
}

export default App;
