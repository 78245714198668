import React from 'react'
import './Navbar.css'


const Navbar=()=>{
    return(
        <div>

<nav class="navbar navbar-expand-lg navbar-dark ">
      <div class="container">
        <a class="navbar-brand nav-logo" href="#"><img src='./images/logo.png' height={"40px"} width={"160px"}/></a>
        {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> */}

        <div class="collapse navbar-collapse nav-item-wrapper" id="navbarsExample07">
          <ul class="navbar-nav">
            <li class="nav-item ">
              <a class="nav-link nav-header-text" href="#about-us">ABOUT US <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item ">
              <a class="nav-link nav-header-text" href="#what-is-levit">WHAT IS LEVIT8?<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item ">
              <a class="nav-link nav-header-text" href="#guidelines">GUIDELINES<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item ">
              <a class="nav-link nav-header-text" href="#why-levit">WHY LEVIT8<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item ">
              <a class="nav-link nav-header-text" href="#video">VIDEOS<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item ">
              <a class="nav-link nav-header-text" href="#product">PRODUCT<span class="sr-only">(current)</span></a>
            </li>
          </ul>
          <a href='#contact-us' style={{textDecoration:"none"}}>
          <button class="nav-btn">
            CONTACT US
          </button>
          </a>
        </div>
      </div>
    </nav>
        </div>
    )
}
export default Navbar;