import React from "react";
import './opening-guide-levit.css';

const OpeningGuideLevit=()=>{
    return(
        <div class="opening-guide-levit" id="guidelines">
          <h1 class="opening-guide-levit-header">OPENING GUIDELINES LEVIT8<span><img src="./images/levit_icon.png" class="opening-guide-levit-img1"/></span></h1>
          <p class="opening-guide-item1">Do you or do you know someone who often spends long hours using their laptop?</p>
          <div class="opening-guide-levit-container">
                <div class="opening-guide-card">
                    <img src="./images/opening_gui_img.png"/>
                  <h1 class="opening-guide-header">STEP-01</h1>
                  <p class="opening-guide-text">Gently open it like a book; look for the logo, which is either in the bottom right-hand corner (there are two of them) or in the top left-hand corner, depending on how you want to open it.</p>
                </div>
                <img src="./images/arrow_right.png" height={"20em"} width={"20em"} class="opening-guide-img1"/>
                <div class="opening-guide-card">
                <img src="./images/opening_gui_img.png"/>
                   <h1 class="opening-guide-header">STEP-02</h1>
                   <p class="opening-guide-text">Twist your right thumb away from you while slowly turning your left thumb towards you. And you need to twist the center seam to pop upwards and the diagonal seam on the right to pop downwards like so. Flip this around, and you can see it gets a diamond shape or fish mouth opening.</p>
                </div>
                <img src="./images/arrow_right.png" height={"20em"} width={"20em"} class="opening-guide-img1"/>
                <div class="opening-guide-card">
                <img src="./images/opening_gui_img.png"/>
                  <h1 class="opening-guide-header">STEP-03</h1>
                  <p class="opening-guide-text">Then you'll lay this far right corner on top of this far left corner flat like so, and it's important that those corners meet and that this lace on flat, because your left hand isn't leaving this crease.</p>
                </div>
                <img src="./images/arrow_right.png" height={"20em"} width={"20em"} class="opening-guide-img1"/>
                <div class="opening-guide-card">
                <img src="./images/opening_gui_img.png"/>
                  <h1 class="opening-guide-header">STEP-04</h1>
                  <p class="opening-guide-text">So it'll set in the bottom, but these corners will still meet and be flat, so you have a little kite shape now.</p>
                </div>
                <img src="./images/arrow_right.png" height={"20em"} width={"20em"} class="opening-guide-img1"/>
                <div class="opening-guide-card">
                <img src="./images/opening_gui_img.png"/>
                  <h1 class="opening-guide-header">STEP-05</h1>
                  <p class="opening-guide-text">You are going to twist again. While keeping these corners flat, twist in this diagonal, which will pop downward, and the other two corners will pop up; keep twisting until the top opens up. </p>
                </div>
          </div>
        </div>
    )
}
export default OpeningGuideLevit;