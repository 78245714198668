import React, {useState, useEffect} from "react";
import "./innovative-product.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const InnovativeProduct=()=>{
  const [isMobile, setIsMobile] = useState(false)
  let timer =0 ;

const handleResize = () => {
  if (window.innerWidth < 850) {
      setIsMobile(true)
  } else {
      setIsMobile(false)
  }
}

const [time, setTime] = useState({h:0,m:0,s:0})
const [secRemaining, setSecRemaining] = useState(44745);
const secondsToTime=(secs)=>{
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    "h": hours,
    "m": minutes,
    "s": seconds
  };
  return obj;
}
const startTimer=() =>{
  if (timer == 0 && secRemaining > 0) {
    timer = setInterval(countDown, 1000);
  }
}

const countDown=() =>{
  let seconds = secRemaining - 1;
  setSecRemaining(seconds)
  setTime(secondsToTime(seconds))
  // Check if we're at zero.
  if (seconds == 0) { 
    clearInterval(timer);
  }
}

const [itemCount,setItemCount] =useState(1);

useEffect(()=>{
  let timeLeftVar = secondsToTime(secRemaining);
    setTime(timeLeftVar)
    startTimer()
    window.addEventListener("resize", handleResize)
},[])
useEffect(() => {
  window.addEventListener("resize", handleResize)
})

    return(
        <div class="innovative-container" id="product">
         <h1 class="innovative-header">INNOVATIVE PRODUCT FOR THIS GENERATION</h1>
         <p class="innovative-text">Crafted from especially tough fabric that is water-repellent and stain-proof. LEVIT8 occupies minimal space. Slip it into your bag, shelf, or laptop case.</p>
         <div class="ip-time-txt"><span class="ip-sale">Sale up to -50% Off!</span> 
          <div class="ip-time-wrapper">
            <span class="ip-hr">{time.h}</span>
            <span class="ip-title">HOURS</span>
          </div>
          <div class="ip-time-wrapper">
            <span class="ip-hr">{time.m}</span>
            <span class="ip-title">MINUTES</span>
          </div>
          <div class="ip-time-wrapper">
            <span class="ip-hr">{time.s}</span>
            <span class="ip-title">SECONDS</span>
          </div>
         </div>
         <div class="inn-wrapper">
         <div class="innovative-image">
         <OverlayTrigger trigger="hover" placement={isMobile?"top-end":"left"} overlay={popoverDurable}>
          <img src="./images/img_plus.png" class="img-durable"/></OverlayTrigger>
          <OverlayTrigger trigger="hover" placement={isMobile?"bottom":"left"} overlay={popoverGrabIt}>
          <img src="./images/img_plus.png" class="img-grabit"/></OverlayTrigger>
          <OverlayTrigger trigger="hover" placement={isMobile?"top":"right"} overlay={popoverWater}>
          <img src="./images/img_plus.png" class="img-water"/></OverlayTrigger>
          <OverlayTrigger trigger="hover" placement={isMobile?"bottom":"right"}  overlay={popoverDurability}>
          <img src="./images/img_plus.png" class="img-durability"/></OverlayTrigger>
           <img src="./images/innovative_image.png"  class="innovative-img-main"/>
           <img src="./images/off_50.png" class="off-img"/>
         </div>
         <div class="buy-wrapper">
           <h1 class="buy-txt">Levit8 an Foldable Multifunction Portable Laptop Stand</h1>
           <p class="buy-price1">Rs. 2,199.00 <span class="buy-price2">Rs. 2,999.00</span></p>
           <div class="pdt-img-container">
            <div class="pdt-img">
              <img src="./images/pdt1.png" class="pdt-img1"></img>
            </div>
            <div>
              <img src="./images/pdt2.png" class="pdt-img1"></img>
            </div>
            <div>
            <img src="./images/pdt3.png" class="pdt-img1"></img>
            </div>
           </div>
           <div class="ic-wrapper">
            <div class="ic" onClick={()=>{
              if(itemCount>1)
                setItemCount((itemCount-1))
            }}>
             <img src="./images/ic_minus.png"></img>
            </div>
            <div class="ic1">
             {itemCount}
            </div>
            <div class="ic" onClick={()=>{
              setItemCount((itemCount+1 ))
            }}>
              <img src="./images/ic_plus.png"></img>
            </div>

           </div>
           <div class="buy-btn">
          <div class="buy-btn1">
         <a href="https://www.flipkart.com/levit8-portable-laptop-stand-non-slip-base-tabletop-risers-medium-size-red-color-home-office/p/itmaa25e24b93cf1?pid=LASGMENXS6WEZMC9&lid=LSTLASGMENXS6WEZMC9B57KBU&marketplace=FLIPKART&q=laptop+stand&store=6bo%2Fai3%2F0rp&srno=s_2_44&otracker=search&otracker1=search&fm=Search&iid=en_VTTeamXkgvp1bcmTPfbHTj0PSquS1iaAMP4vIgPOnO2f0gyIzxkeqybwRyUjF%2FMZtumXLn3q5op0SCAWJl%2BouQ%3D%3D&ppt=sp&ppn=sp&ssid=z3qsqzewww0000001675946479297&qH=1dfaeb77ea773b39" target="_blank"><button class="btn-innova-order">BUY FROM FLIPKART<img src="./images/ic_flipkart.png" class="btn-img"></img></button></a>
         </div>
         <div class="buy-btn1">
         <a href="https://www.amazon.in/dp/B0BTP2FP38?ref_=cm_sw_r_cp_ud_dp_KXDWP9T7GY20NEA0M9KK" target="_blank"><button class="btn-innova-order">BUY FROM AMAZON<img src="./images/ic_amazon.png" class="btn-img"></img></button></a>
         </div>
         </div>
         </div>
         </div>
         
        </div>
    )
}

const popoverGrabIt = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div class="pop-grab-it-wrapper">
        <div class="pop-grab-it-content1"> 
          <p class="pop-header">Weight-bearing</p>
          <p class="pop-text">Levit8 is tough as nails and can carry far more than you think.</p>
        </div>
        <img src="./images/icon_grabit.png" class="pop-grab-it-content2" />
      </div>
    </Popover.Body>
  </Popover>
);

const popoverDurable = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div class="pop-durable-wrapper">
        <div class="pop-durable-content1"> 
          <p class="pop-header">Durable and waterproof</p>
          <p class="pop-text"> it is made of a specially designed tough fabric that is waterproof and stain-proof.</p>
        </div>
        <img src="./images/icon_durable.png" class="pop-durable-content2" />
      </div>
    </Popover.Body>
  </Popover>
);

const popoverDurability = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div class="pop-durability-wrapper">
      <img src="./images/icon_durability.png" class="pop-durability-content2" />
        <div class="pop-water-content1"> 
          <p class="pop-header1">Portable and easy to carry</p>
          <p class="pop-text1">Carry your portable workstation anywhere you want. It takes up little space and can easily slip into your bag, shelf, or laptop case.</p>
        </div>
      </div>
    </Popover.Body>
  </Popover>
);

const popoverWater = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div class="pop-water-wrapper">
      <img src="./images/icon_water.png" class="pop-water-content2" />
        <div class="pop-water-content1"> 
          <p class="pop-header1">Multipurpose</p>
          <p class="pop-text1">Not only can you use this to elevate your laptops, but you can also use it as a temporary folder to keep books, or children can use it to study in bed and many other things. </p>
        </div>
      </div>
    </Popover.Body>
  </Popover>
);

export default InnovativeProduct;