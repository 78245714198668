import React from "react";
import "./why-levit.css";

const WhyLevit=()=>{
    return(
        <div class="why-levit" id="why-levit">
            <h1 class="why-levit-header1">WHY LEVIT<span><img src="./images/levit_icon.png" class="levit-img-4"/></span>?</h1>
             <p class="why-levit-para">Levit8 Co. is a team of three passionate Industrial Designers based in Singapore. We’re geeks and dreamers who love to observe daily life</p>
             <div class="why-levit-container">
                <div class="why-levit-card">
                  <img src="./images/fuss_free.png"/>
                  <h1 class="why-levit-header">FUSS FREE</h1>
                  <p class="why-levit-text">No need for parts or assembly; it provides a seamless transition between sitting and standing to keep you refreshed and alert at work.</p>
                </div>

                <div class="why-levit-card">
                <img src="./images/slim_lightweig.png"/>
                  <h1 class="why-levit-header">SLIM AND LIGHTWEIGHT</h1>
                  <p class="why-levit-text">It is thin and light, and LEVI8 takes up little space, fitting easily into a bag, a shelf, or a laptop case. (while using an S or M-sized LEVIT8)!..</p>
                </div>

                <div class="why-levit-card">
                <img src="./images/ergonomic.png"/>
                  <h1 class="why-levit-header">ERGONOMIC</h1>
                  <p class="why-levit-text">LEVIT8 offers the freedom to stand as well as sit when you are tired or aching from staying in the same position.</p>
                </div>
              </div>

                <div class="why-levit-container">
                  
                <div class="why-levit-card">
                <img src="./images/durable.png"/>
                  <h1 class="why-levit-header">Durability</h1>
                  <p class="why-levit-text">Designed with a special tough fabric that is waterproof as well as stainproof, LEVIT8 is sturdy enough to stay with you on all your adventures.</p>
                </div>
                <div class="why-levit-card">
                 <img src="./images/stable_weight.png"/>
                  <h1 class="why-levit-header">STABLE AND WEIGHT BEARING</h1>
                  <p class="why-levit-text">It is made of strong materials such as fabric and can carry more than you think.</p>
                </div>

                <div class="why-levit-card">
                <img src="./images/versatile.png"/>
                  <h1 class="why-levit-header">VERSATILE</h1>
                  <p class="why-levit-text">It offers a wide range of uses, not just to elevate your laptop; it is up to you to be creative to get the ultimate luxury.</p>
                </div>
                
             </div>
             <div>
             <a href="https://amzn.eu/d/gCNsr9o" target="_blank"><button class="btn-order-now1">ORDER NOW</button></a>
             </div>
        </div>
    )
}
export default WhyLevit;