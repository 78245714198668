import React from 'react';
import  './Footer.css';
import {BsFacebook , BsLinkedin, BsPinterest } from 'react-icons/bs';
import {AiFillTwitterCircle, AiFillInstagram} from 'react-icons/ai';

const Footer = () => {
  return ( 
  <footer>
  <div className='footer'>
  <div className='img1'>
   <a href='#about-us'><img src="./images/footer_logo.png" className="logo1" alt='company logo'/> </a>
   </div>
   <div className='list'>
    <a href="#what-is-levit" >What is levit8 ?</a>
     <a href="#guidelines" >guidelines</a>
    <a href="#why-levit" >why levit8</a>
    <a href="#video" >videos</a>
    <a href="#product" >products</a>
   </div>
   <div className='icons'>
   <a href="https://www.facebook.com/levit8india" target="_blank"><img src="./images/icon_fb.png" className='social'/></a>
     <a href="https://www.instagram.com/levit8_india/" target="_blank"><img src="./images/icon_ig.png" className='social'/></a>
     <a href="https://www.linkedin.com/company/levit8-india/" target="_blank"><img src="./images/icon_ln.png" className='social'/></a>
   </div>
   <hr class="footer-line"/>
   <div className='lower-footer'>
<div className='copyright'>© levit8.com. All Rights Reserved.
</div>
<div className='lower'> <a href="/" >Help</a>
     <a href="/" >FAQ</a>
    <a href="/" >Privacy</a>
    <a href="/" >Warranty</a>
    <a href="/" >Returns</a></div>
   </div> 
    </div>
</footer>
);};

export default Footer;