import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "./About-us.css";

function AboutUs(){
    return(
        <div class="about-us-wrapper" id="about-us">
            <Navbar/>
            <div class="about-container">
                <div class="about-container-item">
                <h1 class="about-content1">LEVIT8 : THE FLAT</h1>
                <p class="about-content2">FOLDING PORTABLE</p>
                <h1 class="about-content1">STANDING DESK.</h1>
                <p class="about-content3"> Turn your ordinary desk into a twistable stand-up desk with the magic of origami.
It is fuss-free! No parts and no assembly.</p>
                <div class="btn-container">
                <a href="https://www.flipkart.com/levit8-portable-laptop-stand-non-slip-base-tabletop-risers-medium-size-red-color-home-office/p/itmaa25e24b93cf1?pid=LASGMENXS6WEZMC9&lid=LSTLASGMENXS6WEZMC9B57KBU&marketplace=FLIPKART&q=laptop+stand&store=6bo%2Fai3%2F0rp&srno=s_2_44&otracker=search&otracker1=search&fm=Search&iid=en_VTTeamXkgvp1bcmTPfbHTj0PSquS1iaAMP4vIgPOnO2f0gyIzxkeqybwRyUjF%2FMZtumXLn3q5op0SCAWJl%2BouQ%3D%3D&ppt=sp&ppn=sp&ssid=z3qsqzewww0000001675946479297&qH=1dfaeb77ea773b39" target="_blank">
                    <button class="order-now-btn">ORDER FROM FLIPKART!</button></a>
                </div>
                </div>
                <div class="about-container-img">
                    <img src="./images/aboutright.png" class="about-img"/>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;