import React from "react"
import "./users-say.css";

const UsersSay=()=>{
  const data = [{
    userName:"jacky",
    title:"Levit8 in Action: My Personal Review!",
    comment: "The Levit8 is the perfect solution for anyone looking to improve their ergonomics. Its adjustable height and sturdy construction make it the ideal choice for anyone who spends long hours working on their laptop.",
    rating:4
  },
  {
    userName:"buckybns",
    title:"My Experience with Levit8!",
    comment: "I never realized how much I needed a laptop stand until I got the Levit8. It has completely transformed my work-from-home setup and has made a huge impact on my comfort and productivity.",
    rating:4
  },
  {
    userName:"chanpreet",
    title:"Surprised with this laptop stand!",
    comment: "I love my Levit8 laptop stand! It has made such a difference in my posture and has helped to alleviate the aches and pains I used to experience from using my laptop for extended periods of time.",
    rating:4
  },
  {
    userName:"mushiruddin",
    title:"My Work-From-Home Game-Changer!",
    comment: "As a graphic designer, I spend a lot of time working on my laptop, and the Levit8 has been a game-changer for me. Its sleek design and adjustable height make it the perfect addition to my workspace.",
    rating:4
  },
]
return(
    <div class="users-say">
        <h1 class="users-say-item">WHAT DO OUR USERS SAY?</h1>
        <div class="say-icon" >
          <img src="./images/say_icon_left.png"/>
          <img src="./images/say_icon_right.png"/>
        </div>
     <div class="users-say-card-container">

  {data.map((item)=>(
  <div class="users-say-card">
      <img src="./images/users_say_icon.png" height={"20px"} width={"31px"} class="users-say-icon1"/>
      <h1 class="users-say-header">{item.title}</h1>
      <p class="users-say-text">{item.comment}</p>
        <div class="users-say-line"></div>
        <div class="user-rating-wrapper">
          <div class="user-ratings">
           {item.rating>=1&&<img src="./images/icon_start_active.png"/>}
           {item.rating<1&&<img src="./images/icon_start_inactive.png"/>}
           {item.rating>=2&&<img src="./images/icon_start_active.png"/>}
           {item.rating<2&&<img src="./images/icon_start_inactive.png"/>}
           {item.rating>=3&&<img src="./images/icon_start_active.png"/>}
           {item.rating<3&&<img src="./images/icon_start_inactive.png"/>}
           {item.rating>=4&&<img src="./images/icon_start_active.png"/>}
           {item.rating<4&&<img src="./images/icon_start_inactive.png"/>}
           {item.rating=5&&<img src="./images/icon_start_active.png"/>}
           {item.rating<5&&<img src="./images/icon_start_inactive.png"/>}
          </div>
          <p>@{item.userName}</p>
        </div>
        </div>))}

   
     </div>
  </div>

   
)
}

export default UsersSay;