import React from "react";
import "./who-is-levit.css";

const WhoIsLevit=()=>{
    return(
        <div class="whos-levit-container">
          <div class="who-is-main-container">
                <p class="levit-content-for">WHO IS LEVIT<span><img src="./images/levit_icon.png" class="levit-img-for"/></span>FOR?</p>
                <p>Do you or someone you know spend a lot of time working on their laptop?</p>
                <div class="levit-for-container" >
                    <div class="levit-for-card">
                  <img src="./images/aff_option_icon.png" class="whos-levit-icon"/>
                     <h1 class="levit-for-header">reasonable choice</h1>
                     <p class="levit-for-txt">I work long hours with my laptop; I want to switch between standing and sitting while I work, but would it be something worth replacing my old, well-functioning table with? </p>
                    </div>
                    <div class="levit-for-card">
                    <img src="./images/home_office_icon.png" class="whos-levit-icon"/>
                     <h1 class="levit-for-header">Suitable for teachers </h1>
                     <p class="levit-for-txt">Sometimes I get messed up with the board and laptop while presenting something. Will a standing lapdesk solve the problem? </p>
                    </div>
                    <div class="levit-for-card">
                    <img src="./images/long_hours_icon.png" class="whos-levit-icon"/>
                      <h1 class="levit-for-header">Home office setup</h1>
                      <p class="levit-for-txt">I work from home, and hunching over my laptop hurts my back and neck; is there a way to fix this? </p>
                    </div>
                    <div class="levit-for-card">
                    <img src="./images/travelling_icon.png" class="whos-levit-icon"/>
                      <h1 class="levit-for-header">Job engaged with traveling</h1>
                      <p class="levit-for-txt">My job is engaged with traveling; would it be ok to replace my perfectly working table?</p>
                    </div>
                    <div class="levit-for-card">
                    <img src="./images/student_icon.png" class="whos-levit-icon"/>
                      <h1 class="levit-for-header">Good for student</h1>
                      <p class="levit-for-txt">My son needs to use his laptop for as long as he has lots of assignments to do, and he spends so much time slouching over it that it concerns me about his health.</p>
                    </div>
                </div>
                <div class="need-this-btn">
                <a href="https://amzn.eu/d/gCNsr9o" target="_blank"> <button class="i-need-this-btn">YES! I NEED THIS</button></a>
                </div>
            </div>
            <div class="featured-container">
              <h5 class="featured-header">YOU GOT US FEATURED ON...</h5>
              <div class="featured-image-container">
                <div class="featured-image">
                  <img src="./images/gizmaz.png" style={{borderRadius:"8px", height:"5em", width:"10em"}}/>
                </div>
                <div class="featured-image">
                  <img src="./images/yd.png" style={{borderRadius:"8px", height:"5em", width:"10em"}}/>
                </div>
                <div class="featured-image">
                  <img src="./images/taxi.png" style={{borderRadius:"8px", height:"5em", width:"10em"}}/>
                </div>
                <div class="featured-image">
                  <img src="./images/slate.png" style={{borderRadius:"8px", height:"5em", width:"10em"}}/>
                </div>
                <div class="featured-image">
                  <img src="./images/core.png" style={{borderRadius:"8px", height:"5em", width:"10em"}}/>
                </div>

              </div>
            </div>
        </div>
    )
}

export default WhoIsLevit;