import React, {useState, useEffect, useRef} from "react";
import "./standing-desk.css"
// import ReactPlayer from "react-player";

const StandingDesk=()=>{
    const [isPlaying, setIsPlaying] = useState(true);
    // const [isMobile, setIsMobile] = useState(false)

    // const handleResize = () => {
    //   if (window.innerWidth < 850) {
    //       setIsMobile(true)
    //   } else {
    //       setIsMobile(false)
    //   }
    // }
    
    // useEffect(() => {
    //   window.addEventListener("resize", handleResize)
    // })
    const videoPlayController=()=>{
      if(isPlaying){
        videoRef.current.pause()
        setIsPlaying(false)
      }else{
        videoRef.current.play()
        setIsPlaying(true)
      }
    }
 const videoRef = useRef(null)
    return(
        <div class="standing-desk" id="video">
         <h1 class="standing-desk-header">
            THE FLAT FOLDING PORTABLE STANDING DESK.
         </h1>
         <p class="standing-desk-text">
         When you get tired or ache from standing in the same position for an extended period of time, Levit8 gives you the freedom to stand or sit.
         </p>
         <div class="video-wrapper">
         {/* <ReactPlayer
            url="./videos/standing_desk_video.mp4"
            width={isMobile?"341px":"683px"}
            height={isMobile?"12em":"24em"}
            playing={isPlaying}
            playIcon={<img src="./images/icon_play.png" style={{borderRadius:"40px"}}/>}
            light="./images/video_thumbnail.png"
            /> */}
            <video muted autoPlay loop style={{"background-image":"./images/video_thumbnail.png"}} 
            playsInline data-wf-ignore="true" data-object-fit="fill" class="standing-desk-video"
            ref={videoRef} onClick={videoPlayController}>
            <source src="./videos/standing_desk_video.mp4" data-wf-ignore="true"/>
            </video>
            {/* <video autoPlay loop style={{"background-image":"./images/video_back.jpg"}} muted playsInline data-wf-ignore="true" data-object-fit="fill">
            <source src="./videos/hoverpen_video.mp4" data-wf-ignore="true"/>
            <source src="https://uploads-ssl.webflow.com/63c630a7dec6c016d250f295/63c66a4d5e55ce08bccaf3e6_Untitled-transcode.webm" data-wf-ignore="true"/>
            </video> */}
         </div>
        </div>
    )
}

export default StandingDesk;