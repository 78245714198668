import React from "react";
import './who-are-we.css';

const WhoAreWe=()=>{
    return(
        <div class="who-are-we-container">
            <div class="who-are-container-items">
              <h2 class="who-are-we-heading">WHO ARE WE?</h2>
              <p class="who-are-items1">Levit8 was founded by three enthusiastic industrial designers from Singapore. We observe people's daily lives and try to figure out what they actually need; we take ideas from the fantasy world and make them real for the real world; we bring functional and fun solutions; and we have six years of industry experience. Our background ranges from experience in industrial design to product engineering to marketing.</p>
              <p class="who-are-items1">We have gone through a massive amount of rigorous ideation, prototyping, and iteration based on feedback from the local community before settling on this age-old technique: the box spiral fold first. Who knew the solution could come from origami? which resulted in something stable, easy to collapse, and beautiful too; a single loop of eight triangular panels looks crazy when it works; get this before it's too late.</p>
              <div class="who-get-btn-wrapper-web">
              <a href="https://www.flipkart.com/levit8-portable-laptop-stand-non-slip-base-tabletop-risers-medium-size-red-color-home-office/p/itmaa25e24b93cf1?pid=LASGMENXS6WEZMC9&lid=LSTLASGMENXS6WEZMC9B57KBU&marketplace=FLIPKART&q=laptop+stand&store=6bo%2Fai3%2F0rp&srno=s_2_44&otracker=search&otracker1=search&fm=Search&iid=en_VTTeamXkgvp1bcmTPfbHTj0PSquS1iaAMP4vIgPOnO2f0gyIzxkeqybwRyUjF%2FMZtumXLn3q5op0SCAWJl%2BouQ%3D%3D&ppt=sp&ppn=sp&ssid=z3qsqzewww0000001675946479297&qH=1dfaeb77ea773b39" target="_blank"><button class="who-are-get-btn">GET THIS NOW!</button></a>
              </div>
            </div>
            <div class="who-are-we-image">
             <img src="./images/who_are_we_image.png" class="who_are_we_image1" style={{borderRadius:"8px"}}/>
            </div>
            <div class="who-get-btn-wrapper-mobile">
              <a href="https://www.flipkart.com/levit8-portable-laptop-stand-non-slip-base-tabletop-risers-medium-size-red-color-home-office/p/itmaa25e24b93cf1?pid=LASGMENXS6WEZMC9&lid=LSTLASGMENXS6WEZMC9B57KBU&marketplace=FLIPKART&q=laptop+stand&store=6bo%2Fai3%2F0rp&srno=s_2_44&otracker=search&otracker1=search&fm=Search&iid=en_VTTeamXkgvp1bcmTPfbHTj0PSquS1iaAMP4vIgPOnO2f0gyIzxkeqybwRyUjF%2FMZtumXLn3q5op0SCAWJl%2BouQ%3D%3D&ppt=sp&ppn=sp&ssid=z3qsqzewww0000001675946479297&qH=1dfaeb77ea773b39" target="_blank"><button class="who-are-get-btn">GET THIS NOW!</button></a>
              </div>
        </div>
    )
}

export default WhoAreWe;