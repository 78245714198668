import React, { useState } from "react";
import "./contact-with.css"

const ContactWith=()=>{
    const [name, setName] = useState("")
    const[mail, setMail] = useState("")
    const [phone, setPhone] = useState("")
    const [msg, setMsg] = useState("")
 return (
    <div  class="contact-with-container" id="contact-us">
        <h1 class="contact-with-header">CONTACT WITH US</h1>
        <div class="contact-us-body">
        <div class="contact-with-item1">
        <form >
        <div class=" form-container mb-3" >
        <input type="text" class="form-control p-lg-3 " id="exampleFormControlInput1" placeholder="Name*" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
        <div class="mb-3">
        <input type="email" class="form-control p-lg-3" id="exampleFormControlInput1" placeholder="Email*" onChange={(e)=>{setMail(e.target.value)}}/>
        </div>
        <div class="mb-3">
        <input type="text" class="form-control p-lg-3" id="exampleFormControlInput1" placeholder="Phone*" onChange={(e)=>{setPhone(e.target.value)}}/>
        </div>
        <div class="mb-3">
        <textarea class="form-control p-lg-3" id="exampleFormControlTextarea1" rows="3" placeholder="Message" onChange={(e)=>{setMsg(e.target.value)}}></textarea>
        </div>
        <div class="btn-send-message1">
        <a class="btn-send-message"
            href={`mailto:info@srvpl.com?&subject=Contacting Regarding Levit8&body=Name: ${name}%0D%0AEmail: ${mail}%0D%0APhone: ${phone}
        %0D%0AMessage: ${msg}`}
        target="_blank">Send Message</a>
        </div>
        </form>
        </div>
        <div class="contact-with-item2">
        <div class="contact-with-card">
            <img src="./images/email.png" height={"16px"}  width={"20px"}/>
            <p class="card-txt">Email</p>
            <p class="card-txt1">hello@srvpl.com</p>
            <div class="btn1-txt1">
               <div class="btn-contact-wrapper">
                <button class="contact-with-btn1">CONTACT</button>
                </div>
                <p class="txt-extra-info">*extra information(optional)</p>
           </div>
       </div>

       
        <div class="contact-with-card">
             <img src="./images/phone.png" height={"16px"}  width={"16px"}/>
             <p class="card-txt">Phone</p>
             <p class="card-txt1">Office. +91-8697216123</p>
             <div class="btn1-txt1">
               <div class="btn-contact-wrapper">
               <button class="contact-with-btn1">CALL</button>
                </div>
                <p class="txt-extra-info">*extra information(optional)</p>
           </div>
        </div>
        </div>
        </div>
        </div>
       
    )

}
export default ContactWith;

