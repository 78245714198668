import React from "react";
import "./what-is-levit.css";

const WhatIsLevit=()=>{
    return(
    <div class="levit-container" id="what-is-levit">
        <img src="./images/what-is-levit-mobile-back.png" class="levit-mobile-back"/>
        <div class="levit-image">
     <img src="./images/levit_img.png" class="levit-img-1"/>
    </div>
    <div class="levit-container-item">
       <p class="levit-content1">WHAT IS LEVIT<span><img src="./images/levit_icon.png" class="levit-img-2"/></span>?</p>
        
        
        <div class="levit-list-item">
            <div  class="hexagon">
                <img src="./images/hexagon.png"/>
                <span class="txt-hex"> 01</span>
            </div>

            <p class="levit-content4">LEVIT8 is a flat folding standing desk, with a twist (literally)!</p>
        </div>

        <div class="levit-list-item">
            <div  class="hexagon">
                <img src="./images/hexagon.png"/>
                <span class="txt-hex"> 02</span>
            </div>

            <p class="levit-content4"> LEVIT8 gives us the freedom of transforming any ordinary desk into a sit-stand desk anytime, anywhere, and quickly!</p>
        </div>
        
        
        <div class="levit-list-item">
            <div  class="hexagon">
                <img src="./images/hexagon.png"/>
                <span class="txt-hex"> 03</span>
            </div>

            <p class="levit-content4">No parts, no assembly. It utilises an age-old box-twist folding technique. So with a simple twist and fold, it flattens so you can easily tuck it away into our shelf or bag.</p>
        </div>

        <div class="levit-list-item">
            <div  class="hexagon">
                <img src="./images/hexagon.png"/>
                <span class="txt-hex"> 04</span>
            </div>

            <p class="levit-content4">No refurnishing. No hefty price tags.</p>
        </div>

        <div class="levit-btn">
        <a href="https://amzn.eu/d/gCNsr9o" target="_blank"><button class="get-this-now-btn">GET this now</button></a>
        </div>
    </div>
    </div>
    )
}
export default WhatIsLevit;