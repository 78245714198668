import React, { useState } from 'react';
import "./our-gallery.css";



const OurGallery=()=>

{
  const [checked, setChecked] = useState("slider1");
const onChecked=(e)=>{
  setChecked(e.target.value)
}
const gotoNextSlider=()=>{
  switch(checked){
    case "slider1":
      setChecked("slider2")
      break;
    case "slider2":
      setChecked("slider3")
      break;
    case "slider3":
      setChecked("slider4")
      break;
    case "slider4":
      setChecked("slider5")
      break;
    case "slider5":
      setChecked("slider1")
      break;
    default:
      setChecked("slider1")
  }
}
const gotoPreviousSlider=()=>{
  switch(checked){
    case "slider1":
      setChecked("slider5")
      break;
    case "slider2":
      setChecked("slider1")
      break;
    case "slider3":
      setChecked("slider2")
      break;
    case "slider4":
      setChecked("slider3")
      break;
    case "slider5":
      setChecked("slider4")
      break;
    default:
      setChecked("slider1")
  }
}

    return(
        <div class="our-gallery">
           <h1 class="our-gallery-header">OUR GALLERY</h1>
           <p class="our-gallery-text">Levit8 is too hardy, and it will stick with you on every adventure and also survive coffee spills. With Levit8,  you can work in style with a smile.</p>
       <div>
       <div class="carousel-wrapper">
    <div class="container" onChange={onChecked}>

      <input type="radio" name="slider" value="slider1" id="s1" checked={checked==="slider1"}/>
      <input type="radio" name="slider" value="slider2" id="s2" checked={checked==="slider2"}/>
      <input type="radio" name="slider" value="slider3" id="s3" checked={checked==="slider3"}/>
      <input type="radio" name="slider" value="slider4" id="s4" checked={checked==="slider4"}/>
      <input type="radio" name="slider" value="slider5" id="s5" checked={checked==="slider5"}/>
    <div class="cards">
      <label for="s1" id="slide1">
        <div class="card1">
          <div class="image">
            <img src="./images/image1.png" alt=""/>
        </div>
        </div>

      </label>

      <label for="s2" id="slide2">
        <div class="card1">
          <div class="image">
            <img src="./images/image2.png" alt=""/>
          </div>
          </div>
      </label>

      <label for="s3" id="slide3">
        <div class="card1">
          <div class="image">
            <img src="./images/image3.png" alt=""/>
          </div>
        </div>
      </label>

      <label for="s4" id="slide4">
        <div class="card1">
          <div class="image">
            <img src="./images/image4.png" alt=""/>
          </div>
        </div>
      </label>

      <label for="s5" id="slide5">
        <div class="card1">
          <div class="image">
            <img src="./images/image5.png" alt=""/>
          </div>
        </div>
      </label>
    </div>
    </div>

    <div class="dot-container">
      <img src="./images/icon_arrow_left.png" class="arrow" onClick={gotoPreviousSlider}/>
      {checked==="slider1" &&<img src="./images/dot_active.png"/>}
      {checked!=="slider1" &&<img src="./images/dot_inactive.png" onClick={()=>{setChecked("slider1")}}/>}
      {checked==="slider2" &&<img src="./images/dot_active.png"/>}
      {checked!=="slider2" &&<img src="./images/dot_inactive.png" onClick={()=>{setChecked("slider2")}}/>}
      {checked==="slider3" &&<img src="./images/dot_active.png"/>}
      {checked!=="slider3" &&<img src="./images/dot_inactive.png" onClick={()=>{setChecked("slider3")}}/>}
      {checked==="slider4" &&<img src="./images/dot_active.png"/>}
      {checked!=="slider4" &&<img src="./images/dot_inactive.png" onClick={()=>{setChecked("slider4")}}/>}
      {checked==="slider5" &&<img src="./images/dot_active.png"/>}
      {checked!=="slider5" &&<img src="./images/dot_inactive.png" onClick={()=>{setChecked("slider5")}}/>}
      <img src="./images/icon_arrow_right.png" class="arrow" onClick={gotoNextSlider}/>
    </div>
  </div>
       </div>
        </div>
    )
}
export default OurGallery;